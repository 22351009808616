<template>
    <div>
    
        <b-row>
            <b-col offset-sm-2 sm="12" md="10" offset-lg="2" lg="8" align-self="center" class="mx-auto">
    
                <b-card class="pricing-basic-value font-weight-bolder text-primary" title="Purchase Internet Data 🚀">
                    <div class="text-center" v-if="load">
                        <b-spinner variant="primary" label="Text Centered" style="width: 3rem; height: 3rem;" class="mr-1" />
                    </div>
                    <b-container v-else>
     <b-form    @submit.prevent="processtransaction">
    
                        <b-row align-v="center" class="mt-3 mb-3">
    
    
                            <b-col v-for="data in userdata" :key="data.id" cols="6" md="3" class="mb-1 ">
                                <b-img :style="addstyle(data.datacode)" @click="selectitem(data.datacode)" thumbnail size="70px" fluid :src="mainurl + data.dataimage" /></b-col>
    
                        </b-row>
    
                        <b-form-group label="Phone Number" label-for="phone">
                            <b-form-input v-model="phone" id="phone" size="lg" placeholder="Phone Number" />
                        </b-form-group>
    
     <b-form-group label="Check the box below if it's a ported number" label-for="ported">
                        <b-form-checkbox
                          v-model="ported"
                          id="ported"
                          value="yes"
                        >
                        
                          Ported number?
                        </b-form-checkbox>
                        </b-form-group>
    
    
    
                        <b-form-group label="Data Plan" label-for="plan" v-if="option != null">
                            <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="dataplanname" taggable push-tags :options="option" placeholder="Please Select Data Plan" class="select-size-lg" />
    
    
    
                        </b-form-group>
    
                        <b-form-group v-if="(dataprice != null) && (option != null)" label="Data Amount" label-for="amount">
                            <b-input-group prepend="₦" append>
                                <b-form-input :placeholder="dataprice" prepend="₦" disabled size="lg" />
                            </b-input-group>
    
                        </b-form-group>
    
                    
                        <div class="demo-inline-spacing">
                            <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="lg" block variant="primary" :disabled="process || loadplan">
                               <b-spinner v-if="process || loadplan"
          small
          type="grow"
        />  
            <span v-else> Continue</span> 
                            </b-button>
                        </div>
     </b-form>
                    </b-container>
    
                </b-card>
            </b-col>
    
        </b-row>
    
    
    
    </div>
</template>

<script>
import { BCard, BSpinner, BFormCheckbox, BAvatar, BInputGroup, BFormRadio, BForm,  BCardText, BButton, BFormInput, BImg, BFormGroup, BContainer, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useServices from "@/auth/jwt/userServices"
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
export default {
    components: {
        BCard,
        BImg,
        BForm,
        BContainer,
        BFormCheckbox,
        BSpinner,
        ToastificationContent,
        BInputGroup,
        BFormRadio,
        BFormInput,
        vSelect,
        BFormGroup,
        BButton,
        BAvatar,
        BCardText,
        BRow,
        BCol,

    },
    directives: {
        Ripple,
    },



    data() {
        return {
            load: false,
            userdata: [],
            loadplan: false,
            process:false,
            ported: false,
            dataprice: null,
            selecteddata: null,
            phone: null,
            pin: null,
            mainurl: jwtDefaultConfig.mainurl,

            selected: null,
            option: null,

        }
    },
    // computed: {
    //    addstyle: function(id) {
    //            return this.selectedairtime == id ? "border: 0.3rem solid green": "papa"
    //     }
    // },
    mounted() {
        this.getdata();
    },

    watch: {
        selected: function name(value) {
            this.dataprice = value.dataprice.toString()
            console.log(value)

        },
       
    },


    computed: {
        checkplan() {


            this.option.length == 0;
        },
        
    
    },

    methods: {
        addstyle(id) {
            return this.selecteddata == id ? "border: 0.3rem solid green" : "papa"
        },

        selectitem(id) {
            this.selecteddata = id;
            this.getdataplans()
        },


        async getdata() {
            this.load = true;
            await useServices.getdata().then(response => {
                this.load = false;

                this.userdata = response.data.data;
                console.log(response.data.data)
            });

            this.load = false;

        },

        async getdataplans() {
            {
                this.loadplan = true;
                this.selected = null;
                this.option = null;
                this.dataprice = null;
                await useServices.getdata({
                    params: {
                        id: this.selecteddata,
                    }
                }).then(response => {
                    this.load = false;

                    this.option = response.data.data;
                    console.log(response.data.data)
                });

                this.loadplan = false;

            }
        },

        async processtransaction() {

            this.process = true;
         
            try {
                await useServices.processdata({
                    network: this.selecteddata,
                    ported: this.ported,
                    phone_number: this.phone,
                    plan: this.selected.dataplancode,
                }).then((result) => {
 this.false = true;

                    if (result.data.data.status == true) {

                        console.log(result.data.data)

                        console.log(result.data.data.data.hash)

                        this.$router.push({name: "transaction", params: { hash: result.data.data.data.hash}});

                       

                    } else {

                        this.$swal({
                            title: 'Error!',
                            text: result.data.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })



                    }



                });
            } catch (error) {
                 this.false = true;
                 console.log(error.response)
                 if (error.response.status == 422) {
           
                 var data = JSON.parse(error.response.data.data)
          for (var k in data) {
        if (data.hasOwnProperty(k)) {

            this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    



                }else {
 this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: "Something Went Wrong, Please Try Again",
                    },
                  })




                }
            }

            this.process = false;



        }



    },





}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
